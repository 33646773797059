import {Form, Button, Col, Container, FloatingLabel, Row, Image, Alert, Spinner, Navbar, Card} from "react-bootstrap";
import {useState} from "react";
import axios from "axios";
import {BsBoxArrowInLeft, BsCalendar, BsX} from "react-icons/bs";
import {MdAccountBalance, MdAttachMoney, MdGavel, MdGroupAdd} from "react-icons/all";

export default function App(){
    const [salarioObj, setSalarioObj] = useState({});
    const [loading, setLoading] = useState(0);
    const [salario, setSalario] = useState(1);
    const [tipo, setTipo] = useState('mensual');

    const obtenerSalarioDeducciones = () => {
        setLoading(1);
        setSalarioObj({});
        axios.get('https://isr.francis.center', { params : { salario, tipo}})
            .then(response => {
                setSalarioObj(response.data);
            })
            .catch(error => {
                setSalarioObj(error.response.data);
            })
            .finally(_ => {
                setLoading(0);
            });

    }

    const formatNumber = number => {
        return Intl.NumberFormat('es-DO', { minimumFractionDigits : 2, maximumFractionDigits: 2}).format(number)
    }


    return <Container
        className="min-vh-100 d-flex flex-column"
        style={{
            backgroundImage: 'url("/background.jpg")',
            backgroundSize: "cover"}}
        fluid
    >
        <Row>
            <Col className="p-0 bg-dark text-white">
                    <div className="text-center h-100 pt-2 pb-2"><h2>Calculadora ISR</h2></div>
            </Col>
        </Row>
        <Row className="half-dark flex-grow-1">
            <Col xs={1} md={3} lg={3} xl={4}></Col>
            <Col className={salarioObj.salarioMensual ? "d-none mt-2" : "mt-2"}>
                <Row className="shadow-lg bg-white rounded m-2 bg-light sliding">
                    <Col xs={12}  className="mb-4 mt-2">
                        <Image src="/2000.jpg" fluid rounded  />
                    </Col>
                    <Col xs={12} className="mb-2">
                        <FloatingLabel label="Salario" controlId="salarioNumero">
                            <Form.Control
                                type="number"
                                placeholder="30000"
                                value={salario}
                                onChange={e => { setSalario(e.target.value)}}
                                onKeyPress={e => {
                                    if(e.key === "Enter")
                                        obtenerSalarioDeducciones();

                                }}
                            />
                        </FloatingLabel>
                    </Col>
                    <Col xs={12} className="mb-4">
                        <FloatingLabel label="Tipo Salario" controlId="salarioNumero">
                            <Form.Select
                                defaultValue={tipo}
                                onChange={e => setTipo(e.target.value)}
                            >
                                <option value="anual">Anual</option>
                                <option value="mensual">Mensual</option>
                            </Form.Select>
                        </FloatingLabel>
                    </Col>
                    <Col xs={12} className="text-center mb-4">
                        <Button onClick={obtenerSalarioDeducciones}>Calcular</Button>
                    </Col>
                    <Col xs={12} className="text-center mt-1 mb-1">
                        {loading === 1 && <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>}
                    </Col>
                    <Col xs={12} className={salarioObj.error ? "" : "d-none"}>
                        {salarioObj.error && <Alert className="popanim" variant="danger">{salarioObj.error}</Alert>}
                    </Col>
                </Row>
            </Col>
            <Col className={salarioObj.salarioMensual ? "mt-2 hideOverflow"
            : "mt-2 d-none hideOverflow"}>
                <Row className="shadow-lg bg-white rounded m-2 bg-light slideFromBot">
                    <Col xs={12} className="p-0" >
                        <Card>
                            <Card.Header style={{ cursor: "pointer"}} onClick={_ => setSalarioObj({})}><BsBoxArrowInLeft /> Regresar</Card.Header>
                            <Card.Body>
                                <Card.Title><BsCalendar /> Salario Mensual: ${formatNumber(salarioObj.salarioMensual)}</Card.Title>
                                <Card.Title><MdGavel /> ISR: ${formatNumber(salarioObj.isrMensual)}</Card.Title>
                                <Card.Title><MdGroupAdd /> Seguro Familiar de Salud (SFS): ${formatNumber(salarioObj.sfsMensual)}</Card.Title>
                                <Card.Title><MdAccountBalance /> Administradoras de Fondo de Pensiones (AFP): ${formatNumber(salarioObj.afpMensual)}</Card.Title>
                                <Card.Title><MdAttachMoney /> Salario Mensual Neto: ${formatNumber(salarioObj.salarioMensualNeto)}</Card.Title>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Col>
            <Col xs={1} md={3} lg={3} xl={4}></Col>
        </Row>
    </Container>
}